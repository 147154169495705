<template>
  <div class="container">
    <div style="min-width: 1000px">
      <div class="lineH-box">
        <div class="lineH"></div>
        <div>基本信息</div>
      </div>
      <div class="table-info">
        <div class="info-row">
          <div class="info-list">
            <div class="info-head">优惠券名称：</div>
            <div class="info-details">{{ form.coupon_name }}</div>
          </div>
          <div class="info-list">
            <div class="info-head">排序：</div>
            <div class="info-details">{{ form.sort }}</div>
          </div>
          <div class="info-list">
            <div class="info-head">使用时间：</div>
            <div class="info-details" v-if="form.time_limit == 1">
              获得后{{ form.time_days }}天有效
            </div>
            <div class="info-details" v-if="form.time_limit == 2">
              {{ form.time[0] }}至{{ form.time[1] }}
            </div>
          </div>
          <div class="info-list">
            <div class="info-head">类型：</div>
            <div class="info-details" v-if="form.coupon_type == 1">
              减免券： 减{{ form.relief_enough }}元商品金额
            </div>
            <div class="info-details" v-if="form.coupon_type == 2">
              折扣券： 打{{ form.discount_enough }}折,最多优惠{{
                form.max_price
              }}
            </div>
            <div class="info-details" v-if="form.coupon_type == 3">
              运费券： 减{{ form.freight_enough }}元
            </div>
          </div>
          <div class="info-list" v-if="form.money">
            <div class="info-head">优惠门槛：</div>
            <div class="info-details">单笔满&nbsp;{{ form.money }}&nbsp;元</div>
          </div>
          <div class="info-list">
            <div class="info-head">退还方式：</div>
            <div class="info-details">
              {{
                form.return_type == "1"
                  ? "不退还"
                  : form.return_type == "2"
                  ? "下单取消可退还"
                  : ""
              }}
            </div>
          </div>
          <div class="info-list">
            <div class="info-head">发送总数：</div>
            <div class="info-details">{{ form.total }}</div>
          </div>
          <div class="info-list" v-if="form.max_get_num">
            <div class="info-head">用户领取数：</div>
            <div class="info-details">{{ form.max_get_num }}</div>
          </div>
          <div class="info-list">
            <div class="info-head">到期提醒：</div>
            <div class="info-details">
              {{
                form.is_notice
                  ? form.time_days < form.notice_day
                    ? `到期前${form.time_days}天提醒`
                    : `到期前${form.notice_day}天提醒`
                  : "不提醒"
              }}
            </div>
          </div>
          <div class="info-list">
            <div class="info-head">推广方式：</div>
            <div class="info-details">
              <span class="info-line" v-if="form.coupon_centre">领券中心</span>
              <span v-if="form.coupon_centre && form.coupon_goods">，</span>
              <span class="info-line" v-if="form.coupon_goods">适用商品</span>
            </div>
          </div>
          <div class="info-list">
            <div class="info-head">领取时间：</div>
            <div class="info-details">
              {{ form.show_time[0] }}至{{ form.show_time[1] }}
            </div>
          </div>
        </div>
      </div>
      <div class="lineH-box">
        <div class="lineH"></div>
        <div>商品设置</div>
      </div>
      <div class="table-info">
        <div>开启部分商品使用：{{ form.open_goods == 0 ? "否" : "是" }}</div>
      </div>
      <div class="table-info">
        <div>开启不适应商品：{{ form.close_goods == 0 ? "否" : "是" }}</div>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="适用商品" name="first">
          <div class="goods-box">
            <div class="table-box">
              <el-table
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%; min-width: 1000px"
                :header-cell-style="{ background: '#F5F5F5' }"
              >
                <el-table-column
                  v-for="item in tableHeaders"
                  :key="item.prop"
                  :prop="item.prop"
                  :label="item.label"
                  :width="item.width"
                  :align="item.align"
                  :fixed="item.fixed"
                >
                  <template slot-scope="scope">
                    <div v-if="item.prop == 'goods_info'" class="goods_info">
                      <div>
                        <img v-if="scope.row.picture == ''" :src="defaultImg" />
                        <img v-else :src="scope.row.picture_str" />
                      </div>
                      <div>
                        {{ scope.row.goods_name }}
                      </div>
                    </div>

                    <div v-else>
                      {{ !scope.row[item.prop] ? "" : scope.row[item.prop] }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <pagination
              v-show="totalNum > 0"
              :total="totalNum"
              :page.sync="pagination.page"
              :limit.sync="pagination.pageNumber"
              @pagination="getList"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="不适用商品" name="second">
          <!-- 选择商品 -->
          <div class="goods-box">
            <div class="table-box">
              <el-table
                :data="tableCloseData"
                tooltip-effect="dark"
                style="width: 100%; min-width: 1000px"
                :header-cell-style="{ background: '#F5F5F5' }"
              >
                <el-table-column
                  v-for="item in tableHeaders"
                  :key="item.prop"
                  :prop="item.prop"
                  :label="item.label"
                  :width="item.width"
                  :align="item.align"
                  :fixed="item.fixed"
                >
                  <template slot-scope="scope">
                    <div v-if="item.prop == 'goods_info'" class="goods_info">
                      <div>
                        <img v-if="scope.row.picture == ''" :src="defaultImg" />
                        <img v-else :src="scope.row.picture_str" />
                      </div>
                      <div>
                        {{ scope.row.goods_name }}
                      </div>
                    </div>

                    <div v-else>
                      {{ !scope.row[item.prop] ? "" : scope.row[item.prop] }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <pagination
              v-show="totalNumClose > 0"
              :total="totalNumClose"
              :page.sync="pagination.page"
              :limit.sync="pagination.pageNumber"
              @pagination="getList"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="lineH-box" style="margin-top: 20px">
        <div class="lineH"></div>
        <div>效果数据</div>
      </div>
      <div class="excle" v-if="!statusCoupon">
        <div class="excle_itemBox">
          <div class="pay_info_box">
            <div
              class="p_info_item"
              :style="
                ColorItem == 1 ? `background: rgb(78, 135, 247, 0.2)` : ''
              "
              @click="clickColorItem(1)"
            >
              <div class="p_info_title">{{ "已发放数量" }}</div>
              <div class="p_info_numb">
                {{ couponstatisticaldata.give_total }}
              </div>
            </div>
            <div
              class="p_info_item"
              :style="
                ColorItem == 2 ? `background: rgb(78, 135, 247, 0.2)` : ''
              "
              @click="clickColorItem(2)"
            >
              <div class="p_info_title">{{ "累计使用数量" }}</div>
              <div class="p_info_numb">{{ couponstatisticaldata.count }}</div>
            </div>
            <div
              class="p_info_item"
              @click="clickColorItem(3)"
              :style="
                ColorItem == 3 ? `background: rgb(78, 135, 247, 0.2)` : ''
              "
            >
              <div class="p_info_title">
                {{ "用券总成交额" }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  popper-class="nowarp"
                  content="使用该优惠券的订单付款总金额"
                  placement="top"
                >
                  <img :src="what" alt="" style="width: 12px; height: 12px" />
                </el-tooltip>
              </div>
              <div class="p_info_numb">
                ￥{{ couponstatisticaldata.pay_money }}
              </div>
            </div>
            <div
              class="p_info_item"
              @click="clickColorItem(4)"
              :style="
                ColorItem == 4 ? `background: rgb(78, 135, 247, 0.2)` : ''
              "
            >
              <div class="p_info_title">
                {{ "优惠总金额" }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  popper-class="nowarp"
                  content="使用该优惠券优惠的总金额"
                  placement="top-end"
                >
                  <img :src="what" alt="" style="width: 12px; height: 12px" />
                </el-tooltip>
              </div>
              <div class="p_info_numb">
                ￥{{ couponstatisticaldata.coupon_money }}
              </div>
            </div>
            <div
              class="p_info_item"
              @click="clickColorItem(5)"
              :style="
                ColorItem == 5 ? `background: rgb(78, 135, 247, 0.2)` : ''
              "
            >
              <div class="p_info_title">
                {{ "用券新客户数" }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  popper-class="nowarp"
                  content="首次使用该优惠券且首次在店铺成交的客户数"
                  placement="top-end"
                >
                  <img :src="what" alt="" style="width: 12px; height: 12px" />
                </el-tooltip>
              </div>
              <div class="p_info_numb">
                {{ couponstatisticaldata.new_user_list }}
              </div>
            </div>
            <div
              class="p_info_item"
              @click="clickColorItem(6)"
              :style="
                ColorItem == 6 ? `background: rgb(78, 135, 247, 0.2)` : ''
              "
            >
              <div class="p_info_title">
                {{ "用券老客户数" }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  popper-class="nowarp"
                  content="首次使用该优惠券支付订单且在店铺有过成交订单的客户数"
                  placement="top-end"
                >
                  <img :src="what" alt="" style="width: 12px; height: 12px" />
                </el-tooltip>
              </div>
              <div class="p_info_numb">
                {{ couponstatisticaldata.old_user_list }}
              </div>
            </div>
            <div
              class="p_info_item"
              @click="clickColorItem(7)"
              :style="
                ColorItem == 7 ? `background: rgb(78, 135, 247, 0.2)` : ''
              "
            >
              <div class="p_info_title">
                {{ "用券新客户订单" }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  popper-class="nowarp"
                  content="用券新用户的总金额/订单数"
                  placement="top"
                >
                  <img :src="what" alt="" style="width: 12px; height: 12px" />
                </el-tooltip>
              </div>
              <div class="p_info_numb">
                ￥{{ couponstatisticaldata.new_user_order_mony }}/{{
                  couponstatisticaldata.new_user_order_count
                }}
              </div>
            </div>
            <div
              class="p_info_item"
              @click="clickColorItem(8)"
              :style="
                ColorItem == 8 ? `background: rgb(78, 135, 247, 0.2)` : ''
              "
            >
              <div class="p_info_title">
                {{ "用券老客户订单" }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  popper-class="nowarp"
                  content="用券老用户的总金额/订单数"
                  placement="top-end"
                >
                  <img :src="what" alt="" style="width: 12px; height: 12px" />
                </el-tooltip>
              </div>
              <div class="p_info_numb">
                ￥{{ couponstatisticaldata.old_user_order_mony }}/{{
                  couponstatisticaldata.old_user_order_count
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="timeTotal">
          <div>统计时间:</div>
          <el-date-picker
            v-model="timeTotal"
            type="daterange"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="changeTime"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div class="echarts_box">
          <div ref="chart" style="width: 90%; height: 376px"></div>
          <div style="width: 90%; text-align: center">
            <div>
              <span
                style="
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;
                  background: #4974f5;
                  display: inline-block;
                  margin-right: 5px;
                "
              ></span>
              <span>{{ title }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="" v-if="statusCoupon">优惠券未生效，暂无数据</div>
    </div>
  </div>
</template>
<script>
import discounts from "../../api/discounts";
import _ from "lodash";
import Pagination from "@/components/Pagination/index";
import what from "../../assets/what.svg";
import { type } from "os";
export default {
  name: "checkGood",
  components: {
    Pagination,
  },
  data() {
    return {
      coupon_data_id: this.$route.query.coupon_data_id,
      module_id_array: window.utils.storage.getter("module_id_array") || [],
      activeName: "first",
      form: {
        sort: undefined,
        coupon_name: "",
        time_limit: 2,
        time: [],
        time_days: undefined,
        coupon_type: "", // 类型
        relief_enough: undefined,
        discount_enough: undefined,
        max_price: undefined,
        freight_enough: undefined,
        money: undefined, // 优惠门槛
        return_type: 1,
        total: undefined,
        max_get_num: 0,
        coupon_centre: "",
        show_time: [],
        coupon_goods: "",
        // show_goods_time: [],
        open_goods: 0,
        close_goods: 0,
        is_notice: "",
        notice_day: "",
        invadate_time_end: "",
        invadate_time_start: "",
      },
      totalNum: 0,
      totalNumClose: 0,
      pagination: {
        page: 1,
        pageNumber: 10,
      },
      tableHeaders: [
        {
          label: "商品",
          prop: "goods_info",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "规格",
          prop: "sku_name",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "价格",
          prop: "price",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "会员价",
          prop: "member_price",
          width: "auto",
          align: "center",
          fixed: false,
        },
        {
          label: "库存",
          prop: "stock",
          width: "auto",
          align: "center",
          fixed: false,
        },
      ],
      tableData: [],
      tableCloseData: [], //不适用商品数据
      groupData: [],
      groupCloseData: [],
      chooseGoodsData: [],
      chooseCloseGoodsData: [],
      couponstatisticaldata: {}, //统计数据
      ColorItem: 1,
      timeTotal: [],
      what: what,
      title: "已发放数量",
      statusCoupon: false,
    };
  },
  watch: {
    chooseGoodsData: {
      handler: function(n, o) {
        this.goodsSelection = n;
      },
      deep: true,
    },
    chooseCloseGoodsData: {
      handler: function(n, o) {
        this.goodsSelection = n;
      },
      deep: true,
    },
    "pagination.pageNumber": {
      handler: function(n, o) {
        this.sortData();
        this.groupGoods();
      },
    },
  },
  computed: {
    defaultImg() {
      return require("../../assets/default_img.png");
    },
    pickerOptions() {
      let that = this;
      return {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        disabledDate(time) {
          let endTime = that.form.invadate_time_end;
          let show_start_time = that.form.invadate_time_start;
          let timeValue = that.dayjs(time).unix();
          let now = that.dayjs().unix();
          if (endTime < now) {
            return timeValue < show_start_time || endTime < timeValue;
          }
          if (now < endTime && show_start_time < now) {
            return timeValue < show_start_time || now < timeValue;
          }
          if (now < show_start_time) {
            return true;
          }
        },
      };
    },
  },
  mounted() {
    this.getCouponInfo();
  },
  methods: {
    getTimeTotal() {
      let that = this;
      let endTime = that.form.invadate_time_end;
      let show_start_time = that.form.invadate_time_start;
      let now = that.dayjs().unix();
      // console.log("now", now);
      if (endTime < now) {
        this.timeTotal = [
          this.dayjs.unix(show_start_time),
          this.dayjs.unix(endTime),
        ];
      }
      if (now < endTime && show_start_time < now) {
        this.timeTotal = [
          this.dayjs.unix(show_start_time),
          this.dayjs.unix(now),
        ];
      }
      if (now < show_start_time) {
        this.timeTotal = [];
      }
      this.getCouponData();
    },
    getCouponInfo() {
      let params = {
        coupon_data_id: this.$route.query.coupon_data_id,
      };
      discounts.getCouponInfo(params).then((res) => {
        let { code, data } = res;
        if (code === 0) {
          let obj = JSON.parse(JSON.stringify(data.info));
          // console.log(obj);
          switch (obj["time_limit"]) {
            case 1:
              obj["time"] = [];
              break;
            case 2:
              obj["time"] = [
                this.dayjs.unix(obj["time_start"]).format("YYYY-MM-DD"),
                this.dayjs.unix(obj["time_end"]).format("YYYY-MM-DD"),
              ];
              obj["time_days"] = undefined;
              break;
          }

          switch (obj["coupon_type"]) {
            case 1:
              obj["relief_enough"] = obj["enough"];
              break;
            case 2:
              obj["discount_enough"] = obj["enough"];
              obj["max_price"] = obj["max_price"];
              break;
            case 3:
              obj["freight_enough"] = obj["enough"];
              break;
          }
          obj["show_time"] = [
            this.dayjs.unix(obj["show_start_time"]).format("YYYY-MM-DD"),
            this.dayjs.unix(obj["show_end_time"]).format("YYYY-MM-DD"),
          ];
          this.chooseGoodsData = obj["goods_list"] || [];
          this.chooseCloseGoodsData = obj["close_goods_list"] || [];
          this.sortData();
          this.groupGoods();
          this.form = {
            coupon_data_id: obj["coupon_data_id"],
            sort: obj["sort"],
            coupon_name: obj["coupon_name"],
            time_limit: obj["time_limit"],
            time: obj["time"],
            time_days: obj["time_days"],
            coupon_type: obj["coupon_type"], // 类型
            relief_enough: obj["relief_enough"],
            discount_enough: obj["discount_enough"],
            max_price: obj["max_price"] === "0.00" ? 0 : obj["max_price"],
            freight_enough: obj["freight_enough"],
            money: obj["money"], // 优惠门槛
            return_type: obj["return_type"],
            total: obj["total"],
            max_get_num: obj["max_get_num"],
            coupon_centre: obj["coupon_centre"],
            show_time: obj["show_time"],
            coupon_goods: obj["coupon_goods"],
            open_goods: obj["open_goods"],
            close_goods: obj["close_goods"],
            is_notice: obj["is_notice"],
            notice_day: obj["notice_day"],
            invadate_time_end: obj["invadate_time_end"],
            invadate_time_start: obj["invadate_time_start"],
          };
          // 优惠券未生效
          console.log(
            "this.form.invadate_time_start",
            this.form.invadate_time_start
          );
          console.log("this.dayjs().unix()", this.dayjs().unix());
          if (this.form.invadate_time_start < this.dayjs().unix()) {
            this.statusCoupon = false;
          } else {
            this.statusCoupon = true;
          }
          this.getTimeTotal();
        }
      });
    },
    // 获取选择商品列表
    getList() {
      let isNull = this.groupData[this.pagination.page - 1];
      if (!isNull) {
        this.tableData = this.groupData[this.pagination.page - 2];
      } else {
        this.tableData = this.groupData[this.pagination.page - 1];
      }
      let isNullclose = this.groupCloseData[this.pagination.page - 1];
      if (!isNullclose) {
        this.tableCloseData = this.groupCloseData[this.pagination.page - 2];
      } else {
        this.tableCloseData = this.groupCloseData[this.pagination.page - 1];
      }
    },
    // 商品分组
    groupGoods() {
      this.totalNum = this.chooseGoodsData.length;
      this.totalNumClose = this.chooseCloseGoodsData.length;
      this.groupData = _.chunk(
        this.getGoodsData(this.chooseGoodsData),
        this.pagination.pageNumber
      );
      this.getList();
      this.groupCloseData = _.chunk(
        this.getGoodsData(this.chooseCloseGoodsData),
        this.pagination.pageNumber
      );
      this.getList();
    },
    //去重
    getGoodsData(arr) {
      // 缓存用于记录
      const cache = [];
      for (const t of arr) {
        // 检查缓存中是否已经存在
        if (
          cache.find((c) => c.goods_id === t.goods_id && c.sku_id === t.sku_id)
        ) {
          // 已经存在说明以前记录过，现在这个就是多余的，直接忽略
          continue;
        }
        // 不存在就说明以前没遇到过，把它记录下来
        cache.push(t);
      }
      // 记录结果就是过滤后的结果
      return cache;
    },
    // 数据排序
    sortData() {
      if (this.activeName == "first") {
        let list = JSON.parse(JSON.stringify(this.chooseGoodsData));
        let arr = [];
        for (let i = 0; i < list.length; i++) {
          if (list[i].hasOwnProperty("filter_id")) {
            list[i]["filter_id"] = i;
            arr.push(list[i]);
          } else if (!list[i].open_spec) {
            list[i]["filter_id"] = i;
            arr.push(list[i]);
          } else {
            for (let j = 0; j < list[i].item.length; j++) {
              list[i]["item"][j]["filter_id"] = list[i]["item"][j].sku_id;
              arr.push(list[i]["item"][j]);
            }
          }
        }
        this.chooseGoodsData = _.uniqBy(arr, "filter_id");
      } else if (this.activeName == "second") {
        let list = JSON.parse(JSON.stringify(this.chooseCloseGoodsData));
        let arr = [];
        for (let i = 0; i < list.length; i++) {
          if (list[i].hasOwnProperty("filter_id")) {
            list[i]["filter_id"] = i;
            arr.push(list[i]);
          } else if (!list[i].open_spec) {
            list[i]["filter_id"] = i;
            arr.push(list[i]);
          } else {
            for (let j = 0; j < list[i].item.length; j++) {
              list[i]["item"][j]["filter_id"] = list[i]["item"][j].sku_id;
              arr.push(list[i]["item"][j]);
            }
          }
        }
        this.chooseCloseGoodsData = _.uniqBy(arr, "filter_id");
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 看板数据点击
    clickItem(val) {
      console.log(val);
    },
    getEchartData(time, str) {
      this.$echarts.dispose(this.$refs.chart); //删除dom结点方便从新渲染
      const chart = this.$refs.chart;
      if (chart) {
        const myChart = this.$echarts.init(chart);
        const option = {
          tooltip: {
            backgroundColor: "rgba(13, 13, 13,0.7)",
            textStyle: {
              color: "rgba(255,255, 255, 1)",
            },
            formatter: function(params) {
              console.log(params); //params是echarts的属性
              let res = params; //变量一个res
              //return回调一个模板字符串，自定义提示框的形状
              return `<div>${res.name}(${res.data.day})</div>
              ${
                res.data.length == 1 && res.data.type == true
                  ? `<div>
                      <div><span style="
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;
                                background: #4974f5;
                                display: inline-block;
                                margin-right: 2px;">
                          </span>
                          <span style="margin-right:30px;">总金额</span>
                          <span>${
                            res.data.str ? res.data.str : res.value
                          }</span>
                      </div>
                      <div>
                      <span style="
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background: #4974f5;
                            display: inline-block;
                            margin-right: 2px;">
                      </span>
                      <span style="margin-right:30px;">订单数</span>
                      <span>${res.data.str1 ? `${res.data.str1}` : "0"}</span>
                      </div>
                    </div>
                  `: `<div><span style="
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: #4974f5;
                    display: inline-block;
                    margin-right: 2px;">
                    </span>
                    <span style="margin-right:30px;">总计</span>
                    <span>${res.data.str ? res.data.str : res.value}${
                            res.data.str1 ? `/${res.data.str1}` : ""
                          }</span>
                    </div>`
              }
              `;
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false, //对齐坐标刻度点
            min: -1,
            max: function(value) {
              return value.max + 1;
            }, //最大x刻度的个数
            data: time,
            axisLabel: {
              formatter: function(value, index) {
                return value;
              },
            },
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: str,
              type: "line",
            },
          ],
        };
        myChart.setOption(option);
        window.addEventListener("resize", function() {
          myChart.resize();
        });
      }
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function() {
          myChart.resize();
        });
      });
    },
    getCouponData() {
      // if (
      //   !(
      //     this.module_id_array.includes("170") ||
      //     this.module_id_array.includes("999")
      //   )
      // ) {
      //   this.$notify.error({
      //     title: "暂无权限，请联系管理员",
      //   });
      //   return;
      // }
      let params = {
        coupon_data_id: this.$route.query.coupon_data_id,
      };
      discounts.getcouponstatisticaldata(params).then((res) => {
        this.couponstatisticaldata = res.data;
        this.getlineData();
      });
    },
    getlineData() {
      // if (
      //   !(
      //     this.module_id_array.includes("171") ||
      //     this.module_id_array.includes("999")
      //   )
      // ) {
      //   this.$notify.error({
      //     title: "暂无权限，请联系管理员",
      //   });
      //   return;
      // }
      if (this.timeTotal) {
        let create_time_start = this.dayjs(this.timeTotal[0]).format(
          "YYYY-MM-DD"
        );
        let create_time_end = this.dayjs(this.timeTotal[1]).format(
          "YYYY-MM-DD"
        );
        let params = {
          coupon_data_id: this.$route.query.coupon_data_id,
          create_time_start: create_time_start,
          create_time_end: create_time_end,
          type: this.ColorItem,
        };
        console.log("params", params);
        discounts.getCouponLineStatisticalData(params).then((res) => {
          let time = res.data.map((el) => {
            return {
              value: this.dayjs(el.time).format("YYYY-MM-DD"),
            };
          });
          let str = res.data.map((e) => {
            let weeks = new Array("日", "一", "二", "三", "四", "五", "六");
            let money = [3, 4, 7, 8];
            return {
              value: e.str,
              str: money.indexOf(this.ColorItem) != -1 ? `￥${e.str}` : false,
              str1: e.str1,
              length: res.data.length,
              type: this.ColorItem == 7 || this.ColorItem == 8 ? true : false,
              day: weeks[this.dayjs(e.time).day()],
            };
          });
          this.getEchartData(time, str);
        });
      }
    },
    clickColorItem(val) {
      this.ColorItem = val;
      let titleBox = [
        "已发放数量",
        "累计使用数量",
        "用券总成交额",
        "优惠总金额",
        "用券新客户数",
        "用券老客户数",
        "用券新客户订单",
        "用券老客户订单",
      ];
      this.title = titleBox[val - 1];
      this.getlineData();
    },
    changeTime() {
      this.getlineData();
    },
  },
};
</script>
<style lang="scss" scoped>
.nowarp {
  white-space: nowrap;
}

.lineH-box {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #606266;
  font-weight: 700;
  margin-bottom: 20px;
  .lineH {
    width: 3px;
    height: 18px;
    background: #4974f5;
    border-radius: 21px;
    margin-right: 8px;
  }
}
.table-info {
  width: 80%;
  padding: 10px 11px;
  .info-row {
    display: flex;
    flex-wrap: wrap;
    .info-list {
      width: 50%;
      display: flex;
      margin-bottom: 10px;
    }
  }
}
.goods-info {
  padding: 10px 11px;
}
.goods-content {
  .table-box {
    .el-table::before {
      height: 0;
    }
    .item {
      display: flex;
      align-items: center;
      img {
        width: 80px;
        height: 80px;
        margin-right: 20px;
      }
    }
  }
}
.goods-box {
  .goods_info {
    display: flex;
    align-items: center;
    img {
      width: 80px;
      height: 80px;
      margin-right: 20px;
    }
  }
}
.excle {
  .excle_itemBox {
    .Item {
      .title {
      }
      .number {
      }
    }
  }
  .timeTotal {
    display: flex;
    align-items: center;
  }
  .echarts_box {
    width: 100%;
  }
}
.pay_info_box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .p_info_item {
    width: 24%;
    height: 70px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid 1px #eeeeee;
    margin-bottom: 15px;
    border-radius: 8px;
    .p_info_title {
      height: 18px;
      line-height: 18px;
      font-size: 12px;
      color: #4d4d4d;
      margin-bottom: 4px;
    }
    .p_info_numb {
      height: 24px;
      font-size: 20px;
      line-height: 24px;
      color: #4d4d4d;
    }
  }
}
</style>
